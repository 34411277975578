import { combineReducers, configureStore } from '@reduxjs/toolkit';
import MissionMapReducer from './reducers/missions/map_reducer';
import AddressReducer from './reducers/address_reducer';
import AlgoliaReducer from './reducers/algolia';
import BatchesReducer from './reducers/batches/batch_reducer';
import FilterReducer from './reducers/batches/filtered_batches';
import SortingMapReducer from './reducers/batches/map';
import MissionsReducer from './reducers/missions/list';
import SelectedMissionReducer from './reducers/missions/selected';
import PartnerReducer from './reducers/partner/partner_reducer';
import SnacksReducer from './reducers/snacks';
import UserReducer from './reducers/users';
import SortingRulesReducer from './reducers/sorting_rules/sorting_rules';
import RfidsBatchesReducer from './reducers/batches/rfid_batch_reducer';


const store = configureStore({
    reducer: {
        user: UserReducer,
        batch: combineReducers({ 
           list: BatchesReducer,
           filter: FilterReducer,
           map: SortingMapReducer,
           rfid: RfidsBatchesReducer,
        }),
        address: AddressReducer,
        algolia: AlgoliaReducer,
        mission: combineReducers({
            list: MissionsReducer,
            selected: SelectedMissionReducer,
            map: MissionMapReducer,
        }),
       snacks: SnacksReducer,
       sortingRules: SortingRulesReducer,
       partner: PartnerReducer, 
    },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type LoadableContext = {
    loading: boolean;
    error: string | null;
}

export type DataContext<T> = LoadableContext & {
    data: T;
} 

export const getStoreState = () => store.getState();