import { Box, CircularProgress, Grid } from '@mui/material';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import MissionsMethods from '../../actions/missions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectAllMissions } from '../../store/reducers/missions/list';
import MissionCard from './MissionCard';

function MissionsPage() {
    const dispatch = useAppDispatch();
    const missions = useAppSelector(selectAllMissions);
    const loading = useAppSelector(state => state.mission.list.loading);
    const partnerID = useAppSelector((state) => state.user.partnerID);
    const email = useAppSelector((state) => state.user.email);

    useEffect(() => {
        if (partnerID && email) {
            dispatch(MissionsMethods.getMissions(partnerID, email));
        }
    }, [dispatch, partnerID, email]);

    return (
        <Box display="flex" flexDirection="column" minHeight="100vh">
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={3} sx={{ overflowY: 'auto', height: "100vh"}} padding={2} paddingBottom={8}>
                    {missions.map(mission => (
                        <Grid key={mission.ID} item xs={12} sm={6} md={3}>
                            <MissionCard missiondata={mission} />
                        </Grid>
                    ))}
                </Grid>
            )}
            <Outlet />
        </Box>
    );
}

export default MissionsPage;
