import React from 'react';
import { ListItem, Checkbox, ListItemText, Divider } from '@mui/material';
import { formatAddress } from '../../helpers/geo';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../locales/translations';
import { MissionStatus } from '../../models/Missions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { SelectedMissionActions } from '../../store/reducers/missions/selected';
import MissionsMethods from '../../actions/missions';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';

interface MissionAddressItemProps {
    index: number;
}

const MissionAddressItem: React.FC<MissionAddressItemProps> = ({ index }) => {
    const { t } = useTranslation([Namespace.GLOSSARY]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { missionID } = useParams<{ missionID: string }>();
    const partnerID = useAppSelector((state) => state.user.partnerID);
    const missionCompleted = useAppSelector(
        (state) => state.mission.selected.selectedMission?.status === MissionStatus.COMPLETED
    );
    const mission = useAppSelector((state) => state.mission.selected.selectedMission);
    const addressData = useAppSelector(state => state.mission.selected.selectedMission?.addresses?.[index])!;

    const handleCheckboxChange = () => {
        if (partnerID && mission?.addresses) {
            const updatedAddresses = mission.addresses.map((address) =>
                address.addressKey === addressData.addressKey
                    ? { ...address, visited: !address.visited }
                    : address
            );

            dispatch(SelectedMissionActions.updateMissionAddresses(updatedAddresses));
            dispatch(
                MissionsMethods.updateMission(partnerID, mission.ID, { addresses: updatedAddresses })
            );
        }
    };

    const handleAddressDetailClick = () => {
        if (missionID) {
            navigate(
                `/${AppRoutes.MISSIONS}/${missionID}/${AppRoutes.BATCH_DETAIL}/${addressData.addressKey}`
            );
        }
    };

    const errorsCountLabel = t('errors_count', {
        count: addressData.errorsCount,
        ns: Namespace.GLOSSARY,
    });
    const imagesCountLabel = t('images_count', {
        count: addressData.batchesCount,
        ns: Namespace.GLOSSARY,
    });

    return (
        <>
            <ListItem
                sx={{
                    '&:hover': {
                        backgroundColor: 'action.hover',
                        cursor: 'pointer',
                    },
                }}
            >
                {!missionCompleted && (
                    <Checkbox
                        edge="start"
                        disableRipple
                        checked={!!addressData.visited}
                        onChange={handleCheckboxChange}
                    />
                )}
                <ListItemText
                    onClick={handleAddressDetailClick}
                    primary={formatAddress(addressData.address)}
                    secondary={`${errorsCountLabel} / ${imagesCountLabel}`}
                />
            </ListItem>
            <Divider />
        </>
    );
};

export default MissionAddressItem;
