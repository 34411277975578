import React from 'react';
import { List } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import MissionAddressItem from './MissionAddressItem';
import { range } from 'lodash';

const MissionAddressList: React.FC = () => {
    const addressesCount = useAppSelector(state => state.mission.selected.selectedMission?.addresses?.length ?? 0);

    return (
        <List>
          {range(addressesCount).map((index) => (
                <MissionAddressItem key={index} index={index}/>
            ))}
        </List>
    );
};

export default MissionAddressList;
